
/**
 * Road Zipper scene options
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
interface Scene {
  id: number;
  scene: string;
  active: boolean;
}
interface SceneResp {
  id: number;
  stateId: number;
}
import { Component, Mixins, Watch } from "vue-property-decorator";
import confirmModal from "@/mixins/confirmModal/confirmModal";
import { mapGetters, mapActions } from "vuex";
import Project from "@/model/Project";
import Section from "@/model/ModelSection";
import { ActionMessage } from "@/model/Messages/statusMessage";
import UserAccess from "@/model/User/UserAccess";

@Component({
  name: "RZPSceneOptions",
  computed: {
    ...mapGetters(["isUserAdmin", "getUserAccess"]),
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject",
      oppositeSections: "GetAllOppositeSections"
    }),
    ...mapGetters("RoadZipperStore", {
      RZP_ACTIVE_SCENE: "RZPActiveScene"
    })
  },
  methods: {
    ...mapActions("RoadZipperStore", {
      GET_ACTIVE_SCENE: "GetRZPActiveScene",
      SWITCH_SCENE: "RZPSceneChange"
    })
  }
})
export default class RZPSceneOptions extends Mixins(confirmModal) {
  /*----------  Vuex  ----------*/

  private readonly currentProject!: Project;
  private readonly RZP_ACTIVE_SCENE!: SceneResp;
  private readonly isUserAdmin!: boolean;
  private readonly getUserAccess!: UserAccess;
  private readonly oppositeSections!: Array<Section>;

  private GET_ACTIVE_SCENE!: Function;
  private SWITCH_SCENE!: Function;

  /*----------  local data  ----------*/
  sceneOptions: Array<Scene> = [
    { id: 1, scene: "scene1", active: false },
    { id: 2, scene: "scene2", active: false },
    { id: 3, scene: "scene3", active: false },
    { id: 4, scene: "scene4", active: false },
    { id: 0, scene: "default", active: true }
  ];
  activeScene: Scene | undefined = { id: 0, scene: "default", active: true };
  sceneUpdateStatus: ActionMessage | null = null;

  /*----------  lifeCycles  ----------*/
  created() {
    this.GET_ACTIVE_SCENE(this.currentProject.id);
  }

  /*----------  Watch  ----------*/
  @Watch("RZP_ACTIVE_SCENE", { immediate: true })
  HandleSceneUpdate() {
    if (!Object.keys(this.RZP_ACTIVE_SCENE).length) {
      return;
    }
    this.activeScene = this.sceneOptions.find((item) => {
      return item.id === this.RZP_ACTIVE_SCENE.stateId;
    });
  }
  @Watch("oppositeSections", { immediate: true })
  checkSectionSettings() {
    let oppositeAq1Settings = this.oppositeSections[0] ? this.oppositeSections[0].state.rzuSettings?.filename : "";

    if (oppositeAq1Settings === "") {
      this.activeScene?.id === 0 ? "" : this.GET_ACTIVE_SCENE(this.currentProject.id);
    }

    switch (oppositeAq1Settings) {
      case "Wup_1_S.bmp":
        this.activeScene?.id === 4 ? "" : this.GET_ACTIVE_SCENE(this.currentProject.id);
        break;
      case "Wup_1_3.bmp":
        this.activeScene?.id === 3 ? "" : this.GET_ACTIVE_SCENE(this.currentProject.id);
        break;

      case "Wup_1_2.bmp":
        this.activeScene?.id === 2 ? "" : this.GET_ACTIVE_SCENE(this.currentProject.id);
        break;

      case "Wup_1_1.bmp":
        this.activeScene?.id === 1 ? "" : this.GET_ACTIVE_SCENE(this.currentProject.id);
        break;
      default:
        break;
    }
  }

  /*----------  Methods  ----------*/
  HandleSceneSelected(itemToUpdate: Scene) {
    this.sceneUpdateStatus = null;
    this.confirm(
      this.$t("project.roadZipper.sceneConfirm.confirm").toString(),
      async () => {
        // Update the scene to the new one
        let res = await this.SWITCH_SCENE({
          projectId: this.currentProject.id,
          stateId: itemToUpdate.id
        });
        if (res.status !== 200) {
          this.sceneUpdateStatus = {
            class: "danger",
            msg: res
          };
          return;
        }

        this.sceneUpdateStatus = {
          class: "success",
          msg: this.$t("project.roadZipper.sceneConfirm.sceneSuccess").toString()
        };
      },
      () => {
        this.GET_ACTIVE_SCENE(this.currentProject.id);
      }
    );
  }
}
